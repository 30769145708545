import SupplierService from '@/services/suppliers'
import FileService from '@/services/files'
import notify from '@/utils/notifications'

const setError = (commit, error) => {
  commit('setError', true)
  notify(error.title, error.message, error.type)
  setTimeout(() => commit('setError', false), 2000)
  return false
}

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    suppliers: [],
    representatives: [],
    supplier: {},
    supplierNote: {},
    supplierAddress: {},
    supplierContact: {},
    supplierRepresentative: {},
    supplierTypes: [
      { value: null, text: 'Select the Type' },
      { value: 1, text: 'Distributor' },
      { value: 2, text: 'Brand' }
    ],
    supplierAccountStatuses: [
      { value: null, text: 'Select the Acount Status' },
      { value: 1, text: 'New' },
      { value: 2, text: 'Deleted' },
      { value: 3, text: 'Registered' },
      { value: 4, text: 'App. Form Sent' },
      { value: 5, text: 'Resale Cert. Sent' },
      { value: 6, text: 'Approved' },
      { value: 7, text: 'Reproved' }
    ],
    supplierStates: [
      { value: null, text: 'Select the State' },
      { value: 'AC', text: 'Acre' },
      { value: 'AL', text: 'Alagoas' },
      { value: 'AM', text: 'Amazonas' },
      { value: 'AP', text: 'Amapá' },
      { value: 'BA', text: 'Bahia' },
      { value: 'CE', text: 'Ceará' },
      { value: 'DF', text: 'Distrito Federal' },
      { value: 'ES', text: 'Espírito Santo' },
      { value: 'GO', text: 'Goiás' },
      { value: 'MA', text: 'Maranhão' },
      { value: 'MT', text: 'Mato Grosso' },
      { value: 'MS', text: 'Mato Grosso do Sul' },
      { value: 'MG', text: 'Minas Gerais' },
      { value: 'PA', text: 'Pará' },
      { value: 'PB', text: 'Paraíba' },
      { value: 'PR', text: 'Paraná' },
      { value: 'PE', text: 'Pernambuco' },
      { value: 'PI', text: 'Piauí' },
      { value: 'RN', text: 'Rio Grande do Norte' },
      { value: 'RS', text: 'Rio Grande do Sul' },
      { value: 'RJ', text: 'Rio de Janeiro' },
      { value: 'RO', text: 'Rondônia' },
      { value: 'RR', text: 'Roraima' },
      { value: 'SC', text: 'Santa Catarina' },
      { value: 'SP', text: 'São Paulo' },
      { value: 'SE', text: 'Sergipe' },
      { value: 'TO', text: 'Tocantins' }
    ],
    isLoading: false,
    error: false
  },
  // -----------------------------------------------------------------
  getters: {
    suppliers: state => {
      return state.suppliers
    },
    supplier: state => {
      return state.supplier
    },
    supplierId: state => {
      return state.supplier ? state.supplier.id : null
    },
    supplierTypes: state => {
      return state.supplierTypes
    },
    supplierStates: state => {
      return state.supplierStates
    },
    supplierAccountStatuses: state => {
      return state.supplierAccountStatuses
    },
    isLoading: state => {
      return state.isLoading
    },
    supplierAddress: state => {
      return state.supplierAddress
    },
    supplierAddressId: state => {
      return state.supplierAddress ? state.supplierAddress.id : null
    },
    supplierContact: state => {
      return state.supplierContact
    },
    supplierContactId: state => {
      return state.supplierContact ? state.supplierContact.id : null
    },
    supplierNote: state => {
      return state.supplierNote
    },
    supplierNoteId: state => {
      return state.supplierNote ? state.supplierNote.id : null
    },
    error: state => {
      return state.error
    },
    supplierRepresentative: state => {
      return state.supplierRepresentative
    },
    supplierRepresentativeId: state => {
      return state.supplierRepresentative ? state.supplierRepresentative.id : null
    },
    representatives: state => {
      return state.representatives
    },
  },
  // -----------------------------------------------------------------
  mutations: {
    setSuppliers: (state, data) => {
      state.suppliers = data
    },
    setSupplier: (state, data) => {
      state.supplier = data
    },
    setLoading: (state, data) => {
      state.isLoading = data
    },
    setSupplierNote: (state, data) => {
      state.supplierNote = data
    },
    setSupplierAddress: (state, data) => {
      state.supplierAddress = data
    },
    setSupplierContact: (state, data) => {
      state.supplierContact = data
    },
    setError: (state, data) => {
      state.error = data
    },
    setRepresentative: (state, data) => {
      state.supplierRepresentative = data
    },
    setRepresentatives: (state, data) => {
      state.representatives = data
    },
  },
  // -----------------------------------------------------------------
  actions: {

    // -----------------------------------------------------------------
    // Loading
    // -----------------------------------------------------------------
    async setLoading ({ commit }, state) {
      commit('setLoading', state)
    },

    // -----------------------------------------------------------------
    // Cleaning
    // -----------------------------------------------------------------
    async cleanState ({ commit }) {
      commit('setSuppliers', [])
      commit('setSupplier', {})
      commit('setSupplierNote', {})
      commit('setSupplierAddress', {})
      commit('setSupplierContact', {})
    },

    // -----------------------------------------------------------------
    // Suppliers
    // -----------------------------------------------------------------
    async loadSuppliers ({ commit }) {
      try {
        const suppliers = await SupplierService.suppliers()
        commit('setSuppliers', suppliers.data)
      } catch (error) {
        setError(commit, error)
      }
    },

    // -----------------------------------------------------------------
    // Supplier
    // -----------------------------------------------------------------
    async loadSupplier ({ commit }, id) {
      try {
        const supplier = await SupplierService.supplier(id)
        commit('setSupplier', supplier.data)
      } catch (error) {
        setError(commit, error)
      }
    },
    async cleanSupplier ({ commit }) {
      commit('setSupplier', { supplierTypeId: null, supplierAccountStatusId: null })
    },
    async saveSupplier ({ commit, getters, dispatch }, payload) {
      const selectedSupplierId = getters.supplierId
      var message = 'Supplier saved'
      try {
        if (selectedSupplierId) {
          await SupplierService.updateSupplier(selectedSupplierId, payload)
          await dispatch('loadSupplier', selectedSupplierId)
        } else {
          const newSupplier = await SupplierService.addSupplier(payload)
          await dispatch('loadSupplier', newSupplier.data.id)
          message = 'Supplier created'
        }
        notify('Success', message, 'success')
      } catch (error) {
        setError(commit, error)
      }
    },
    async deleteSupplier ({ commit, dispatch }, supplierId) {
      try {
        await SupplierService.deleteSupplier(supplierId)
        notify('Success', 'Supplier Deleted', 'success')
      } catch (error) {
        setError(commit, error)
      }
    },

    // -----------------------------------------------------------------
    // Supplier Address
    // -----------------------------------------------------------------
    async setSupplierAddress ({ commit }, payload) {
      commit('setSupplierAddress', payload)
    },

    async saveAddress ({ commit, getters, dispatch }, payload) {
      const selectedSupplierAddressId = getters.supplierAddressId
      const selectedSupplierId = getters.supplierId
      var message = 'Supplier Address saved'
      try {
        if (selectedSupplierAddressId) {
          await SupplierService.updateSupplierAddress(selectedSupplierAddressId, payload)
        } else {
          await SupplierService.addSupplierAddress(selectedSupplierId, payload)
          message = 'Supplier Address created'
        }
        await dispatch('loadSupplier', selectedSupplierId)
        notify('Success', message, 'success')
      } catch (error) {
        setError(commit, error)
      }
    },

    async deleteAddress ({ commit, getters, dispatch }, supplierAddressId) {
      try {
        const selectedSupplierId = getters.supplierId
        await SupplierService.deleteSupplierAddress(supplierAddressId)
        await dispatch('loadSupplier', selectedSupplierId)
        notify('Success', 'Supplier Deleted', 'success')
      } catch (error) {
        setError(commit, error)
      }
    },

    // -----------------------------------------------------------------
    // Supplier Contact
    // -----------------------------------------------------------------
    async setSupplierContact ({ commit }, payload) {
      commit('setSupplierContact', payload)
    },
    async saveContact ({ commit, getters, dispatch }, payload) {
      const selectedSupplierContactId = getters.supplierContactId
      const selectedSupplierId = getters.supplierId
      var message = 'Supplier Contact saved'
      try {
        if (selectedSupplierContactId) {
          await SupplierService.updateSupplierContact(selectedSupplierContactId, payload)
        } else {
          await SupplierService.addSupplierContact(selectedSupplierId, payload)
          message = 'Supplier Contact created'
        }
        await dispatch('loadSupplier', selectedSupplierId)
        notify('Success', message, 'success')
      } catch (error) {
        setError(commit, error)
      }
    },

    async deleteContact ({ commit, getters, dispatch }, supplierContactId) {
      try {
        const selectedSupplierId = getters.supplierId
        await SupplierService.deleteSupplierContact(supplierContactId)
        await dispatch('loadSupplier', selectedSupplierId)
        notify('Success', 'Supplier Deleted', 'success')
      } catch (error) {
        setError(commit, error)
      }
    },

    // -----------------------------------------------------------------
    // Supplier Note
    // -----------------------------------------------------------------
    async setSupplierNote ({ commit }, payload) {
      commit('setSupplierNote', payload)
    },
    async saveNote ({ commit, getters, dispatch }, payload) {
      const selectedSupplierId = getters.supplierId
      const selectedSupplierNoteId = getters.supplierNoteId
      try {
        if (selectedSupplierNoteId) {
          await SupplierService.updateSupplierNote(selectedSupplierNoteId, payload)
        } else {
          await SupplierService.addSupplierNote(selectedSupplierId, payload)
        }
        await dispatch('loadSupplier', selectedSupplierId)
      } catch (error) {
        setError(commit, error)
      }
    },
    async deleteSupplierNote ({ commit, getters, dispatch }) {
      const selectedSupplierNoteId = getters.supplierNoteId
      try {
        await SupplierService.deleteSupplierNote(selectedSupplierNoteId)
        dispatch('loadSupplier', getters.supplierId)
      } catch (error) {
        setError(commit, error)
      }
    },


    // -----------------------------------------------------------------
    // Supplier File
    // -----------------------------------------------------------------
    async deleteSupplierFile ({ commit, getters, dispatch }, uid) {
      try {
        await FileService.DeleteFile(uid)
        dispatch('loadSupplier', getters.supplierId)
        notify('Success', 'File Deleted with success', 'success')
      } catch (error) {
        setError(commit, error)
      }
    },

    // -----------------------------------------------------------------
    // Supplier Contact
    // -----------------------------------------------------------------
    async setRepresentative ({ commit }, payload) {
      commit('setRepresentative', payload)
    },
    async saveRepresentative ({ commit, getters, dispatch }, payload) {
      const selectedSupplierRepresentativeId = payload.id
      const selectedSupplierId = payload.supplierId
      var message = 'Supplier Representative saved'
      try {
        if (selectedSupplierRepresentativeId) {
          await SupplierService.updateSupplierRepresentative(selectedSupplierRepresentativeId, payload)
        } else {
          await SupplierService.addSupplierRepresentative(selectedSupplierId, payload)
          message = 'Supplier Representative created'
        }
        await dispatch('loadSupplier', selectedSupplierId)
        notify('Success', message, 'success')
      } catch (error) {
        setError(commit, error)
      }
    },

    async deleteRepresentative ({ commit, getters, dispatch }, supplierRepresentativeId) {
      try {
        const selectedSupplierId = getters.supplierId
        await SupplierService.deleteSupplierRepresentative(supplierRepresentativeId, selectedSupplierId)
        await dispatch('loadSupplier', selectedSupplierId)
        notify('Success', 'Representative x Supplier Deleted', 'success')
      } catch (error) {
        setError(commit, error)
      }
    },

    async loadRepresentatives ({ commit, getters }) {
      try {
        const selectedSupplierId = getters.supplierId
        const representatives = await SupplierService.loadRepresentativeNotInSupplier(selectedSupplierId)

        var data = [...representatives.data]

        // Replace alll array with current supplier Id
        data.forEach(element => {
          element.supplierId = selectedSupplierId
        });

        commit('setRepresentatives', data)
      } catch (error) {
        setError(commit, error)
      }
    },
  }
}
